/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-dots': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 8a1 1 0 11-2 0 1 1 0 012 0m4 0a1 1 0 11-2 0 1 1 0 012 0m3 1a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M2.165 15.803l.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 008 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 01-.524 2.318l-.003.011a11 11 0 01-.244.637c-.079.186.074.394.273.362a22 22 0 00.693-.125m.8-3.108a1 1 0 00-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 01-2.088-.272 1 1 0 00-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 00.398-2"/>',
    },
});
